:root {
    --bg: #E5E5EA;
    --white: white;
    --gray: #edf0f1;
    --gray1: #394867;
    --gray2: #212A3E;
    --gray3: #2f3136;
    --gray4: #394867;
    --op: #394867;
    --emka: #24252a;
    --shad: black;
    --bw: #F1F6F9;
    --gr: #202225;
    --gr2: #2f3136;
    --gr3: #2f3136;
    --cien: rgba(0, 0, 0, 0.5);
    --obwod: #9BA4B5d9;
    --shadow-black-100: 0 5px 15px rgba(0, 0, 0, 0.3);

    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
}

::-webkit-scrollbar {
    display: inline-block;
    width: 0.5rem;
}

::-webkit-scrollbar-track {
    background: var(--gray2);
}

::-webkit-scrollbar-thumb {
    background: var(--gray1);
    border-radius: 100px;
    height: 10px;
}

@font-face {
    font-family: Cocon;
    src: url('../fonts/Cocon-Regular-Font.otf');
}



html {
    height: 100%;
}

body {
    background-color: var(--gray2);
    height: 100%;
    margin: 0;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: background 0.3s ease-in-out, color 0.6s ease-in-out;
}

body.light {
    transition: background 0.3s ease-in-out, color 0.6s ease-in-out;
    --send-color: white;
    --bg: #E5E5EA;
    --white: white;
    --gray: #3b3b3b;
    --gray1: white;
    --gray2: #E5E5EA;
    --gray3: #E5E5EA;
    --gray4: #fafafa;
    --op: #c0c1c4;
    --emka: #787878;
    --shad: #b0bec5;
    --bw: #171717;
    --gr: #E5E5EA;
    --gr2: #b0bec5;
    --cien: rgba(0, 0, 0, 0.01);
    --gr3: #efeff1;
    --obwod: #768D70;
    --shadow-black-100: 0 5px 15px rgba(0, 0, 0, 0.1);
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

button {
    all: unset;
}

/*----------------------
 Navigation Section
----------------------*/

header {
    position: fixed;
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 5%;
    /*border-radius: 0 0 20px 0;*/
    transition: all .3s ease-in-out;
    background-color: var(--gray2);
}

.logo {
    cursor: pointer;
    font-family: "ff-cocon-pro", sans-serif;
    font-weight: 1000;
    font-size: 1.7rem;
    color: var(--gray);
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    letter-spacing: -4px;
}

nav {
    order: 1;
}

a {
    cursor: pointer;
}

.nav__links a,
.cta,
.overlay__content a {
    font-family: 'JetBrains Mono', monospace;
    font-weight: 500;
    color: var(--gray);
    text-decoration: none;
}

.nav__links li {
    padding: 8px 20px;
    transition: all 0.3s ease-in-out;
    border-radius: 10px;
}

.nav__links {
    list-style: none;
    display: flex;
    font-size: 16px;
}

.nav__links a {
    margin-left: 14px;
}

.nav__links a:target li {
    color: #ECB365;
}

.nav__links li:hover {
    color: #ECB365;
    cursor: pointer;
}

.nav__links i {
    font-size: 14px;
    color: #ECB365;
}

.toggle-theme i {
    color: var(--bw);
    transition: all 0.3s ease-in-out;
}

.toggle-theme i:hover {
    color: #ECB365;
}

/*
header .nav__links .active {
    color: #ECB365;
}*/


.header-shrink {
    background-color: rgba(33, 42, 62, 0.85);
    backdrop-filter: blur(8px);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2)
}

.light .header-shrink {
    background-color: rgba(229, 229, 234, 0.5);
    backdrop-filter: blur(6px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

header.hidden {
    transform: translateY(-140%);
}





.home {
    font-family: 'JetBrains Mono', monospace;
    font-weight: 500;
    color: white;
    /*padding: 30px 30px 30px;*/
    position: relative;
    z-index: 1;
    top: 100px
}

jk {
    color: #ECB365;
}


.drop {
    justify-content: center;
    text-align: center;
    margin-top: -30px;
}


.name {
    position: relative;
    max-width: 280px;
    z-index: 2;
    font-family: 'JetBrains Mono', monospace;
    font-weight: 700;
    color: var(--bw);
    font-size: 20px;
    left: 0;
    right: 0;
    bottom: 62px;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5px;
    padding-right: 5px;
}

.name span {
    font-size: 18px;
    margin-top: 5px;
    font-family: "ff-cocon-pro", sans-serif;
    display: block;
}

::selection {
    background: #9BA4B5;
}




/*----------------------
 Home Content Section
----------------------*/



.content {
    padding-left: 420px;
    padding-right: 420px;
    max-width: 2000px;
    margin: 0px auto;
    width: 100%;
}


.startname {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    color: var(--bw);
    font-size: 20px;
    font-weight: bolder;
    padding-bottom: 200px;
}

.hello {
    margin-top: 10px;
    opacity: 0.8;
    font-weight: lighter;
    line-height: 30px;
    max-width: 110vh;
}


.welcome {
    font-size: 16px;
    font-weight: 600;
}

.namesubname {
    font-size: 60px;
    display: block;
    color: var(--bw);
    margin-left: -4px;
    letter-spacing: .04em;
}

.einstein {
    font-size: clamp(1rem, 3vw + 1rem, 4rem);
    position: relative;
    width: max-content;
    font-style: normal !important;
}

.einstein::before,
.einstein::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.einstein::before {
    background: var(--gray2);
    animation: typewriter 3s steps(15) 1s forwards;
}

.einstein::after {
    width: 0em;
    background: #ECB365;
    animation: typewriter 3s steps(15) 1s forwards,
        blink-caret 750ms step-end 13;
    animation-fill-mode: forwards;
    /* Add this line */
}

@keyframes typewriter {

    to {
        left: 100%;
    }
}

@keyframes blink-caret {
    50% {
        opacity: 0;
    }
}


.bolden {
    font-weight: 1000;
}

.resume-cont {
    display: flex;
    align-items: center;
}

.resume-button {
    margin-top: 50px;
    cursor: pointer;
    padding: 10px;
    border: 2px solid #ECB365;
    border-radius: 12px;
    width: 180px;
    text-align: center;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
}

.resume-button:hover {
    color: #ECB365;
}

.resume {
    font-weight: normal;
    padding: 10px;
    margin-left: 20px;
    margin-top: 50px;
    cursor: pointer;
}

.aboutme {
    color: var(--bw);
}

.titleabout {
    color: var(--bw);
    font-size: 24px;
    font-weight: 600;
}

.textabout {
    line-height: 22px;
    margin-top: 10px;
    font-weight: 300;
}

.ndtext {
    margin-top: 24px;

}

.clickable {
    color: #ECB365;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

.clickable:hover {
    color: var(--bw);
}


.quote {
    padding-top: 80px;
    margin-left: auto;
    align-self: right;
    width: 500px;
    position: relative;
    color: var(--bw);
    font-weight: 400;
    font-style: italic;
}


.quote span {
    position: absolute;
    font-size: 16px;
    margin-top: 8px;
    color: #ECB365;
    display: block;
    text-align: right;
    transition: all 0.3s ease-in-out;
    right: 0;
}

.quote span:hover {
    color: var(--bw);
}



.fa-quote-left {
    font-size: smaller;
    margin-right: 4px;
    position: relative;
    top: 5px;
}

.fa-quote-right {
    font-size: smaller;
    margin-left: 4px;
    position: relative;
    bottom: 5px;
}

.langicons {
    margin-top: 0px;
    display: flex;
    flex-direction: row;
}

.langicons i {
    width: 10px;
    color: var(--bw);
}


.langicons li {
    background-color: #ECB365;
    border-radius: 5px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.langicons li:not(:first-child) {
    margin-left: 10px;
}



@media only screen and (max-width: 800px) {
    .langicons {
        display: block;
    }

    .langicons li {
        background-color: transparent;
        border-radius: 0;
        padding: 0;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0 !important;
    }

    .langicons i {
        color: #ECB365;
    }

    .react-logo {
        display: none;
    }
}


.item {
    max-width: 500px;
}

.project .fa-github {
    margin-right: 30px;
}

/*----------------------
 Projects Content Section
----------------------*/

.projects {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
    color: var(--bw);
    margin-top: 20px;
    padding-bottom: 120px;
    justify-items: center;
}

.project {
    margin-bottom: 40px;
    border-radius: 10px;
    position: relative;
    /* Added */
    backdrop-filter: blur(0);
}

.project img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0px;
    filter: grayscale(0%) brightness(40%);
    /* Added */
    border-radius: 6px;
}

.project .names {
    font-size: 22px;
    max-width: 500px;
    font-weight: 600;
    z-index: 3;
}

.description span {
    display: block;
    margin-top: 18px
}

.project .names i {
    cursor: pointer;
    padding: 5px;
}

.project .description {
    color: #F1F6F9;
    position: absolute;
    padding: 16px;
    backdrop-filter: blur(1.4px);
    background-color: rgba(33, 43, 63, 0.37);
    /* Added */
    border-radius: 0px;
    z-index: 2;
    height: 100%;
    border-radius: 6px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
}

.light .description {
    background-color: rgba(247, 247, 247, 0.04);
    /* Added */
}

.light .project img {
    filter: grayscale(0%) brightness(60%);
    /* Added */
}


.project .description:hover {
    backdrop-filter: blur(0px);
    background-color: rgba(33, 43, 63, 0.1);
    /* Added */
}

.project-left .names {
    margin-top: 80px
}


.project .linksite {
    position: absolute;
    bottom: 14px;
    font-size: 14px;
    width: 100%;
}

.project .description i {
    cursor: pointer;
    text-decoration: none;
    color: #F1F6F9;
    font-size: 14px;
    transition: all .3s ease-in-out;
    position: absolute;
    right: 30px;
    margin-top: 4px;
    left: auto;
}




.projects-more .description i {
    position: absolute;
    right: 0px;
    margin-top: 42px;
    padding: 5px;
    cursor: pointer;
    text-decoration: none;
    color: var(--bw);
    transition: all .3s ease-in-out;
}

.description i:hover {
    color: #ECB365;
}

.projects-more .linksite {
    position: absolute;
    padding: 5px;
    font-size: 16px;
    margin-top: 20px;
    margin-left: -18px;
    font-size: 14px;
}


.proj {
    padding-bottom: 40px;
}


.projects-more {
    padding-bottom: 80px;
    color: var(--bw);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
}

.projects-more .description {
    padding: 15px;
    background-color: var(--gray1);
    max-width: 100%;
    border-radius: 10px;
    padding-bottom: 42px;
    position: relative;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    /*box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);*/
}

.proj .description i {
    position: relative;
    margin-top: 0px;
}

.title i {
    color: #ECB365
}

.proj:not(:first-child) {
    margin-left: 0px;
}

.projects-more .title {
    font-size: 20px;
    padding-bottom: 10px;
    font-weight: 600;
}

.proj .linksite {
    font-size: smaller;
    position: absolute;
    bottom: 2px;
    left: 25px;
    width: 100%;
}

.projectmore .names {
    margin-left: 0px;
}

.linko {
    font-size: 18px;
    padding: 10px !important;
    position: absolute !important;
    text-align: right !important;
    margin-right: 10px;
    bottom: 0px;
}

.linko2 {
    margin-right: 46px;
    bottom: 0.2px;
}

.projects-more .description {
    height: 230px;

}


/*----------------------
 Icons Section
----------------------*/



.social-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: calc(5% - 4px);
    bottom: 120px;
    font-size: 24px;
    color: var(--bw);
}

.social-icons i {
    margin-bottom: 18px !important;
    cursor: pointer;
    transition: all .3s ease-in-out;
    text-decoration: none !important;
    color: var(--bw) !important;
}

.social-icons i:hover {
    color: #ECB365 !important;
}

.social-icons i:last-child {
    margin-bottom: 0;
    position: relative;
}

.line {
    content: "";
    position: absolute;
    height: 120px;
    width: 2px;
    background-color: var(--bw);
    right: calc(50% - 1px);
    top: calc(100% + 10px);
    cursor: default;
}

.email {
    position: absolute;
    transform: rotate(90deg);
    margin-bottom: 240px;
}

.email a {
    text-decoration: none;
    color: var(--bw);
    transition: all .3s ease-in-out;
}

.email a:hover {
    color: #ECB365;
}

.resume-cont a {
    text-decoration: none;
    color: var(--bw);
}

.r-icons {
    left: calc(95% + 4px);
    font-size: medium;
    font-weight: 500;
    cursor: pointer;
    bottom: 120px;
}


.intresticons {
    display: flex;
}

.intresticons li:not(:first-child) i {
    padding-left: 20px;
}

.intresticons i {
    margin-right: -2px;
}


.preloader {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    z-index: 2100;
    background-color: var(--gray2);
    display: flex;
    align-items: center;
    justify-content: center;
}

.preloader span {
    display: block;
    height: 60px;
    width: 60px;
    background-color: var(--gray1);
    border-radius: 50%;
    animation: zoomInOut_1 1s ease infinite;
}

@keyframes zoomInOut_1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

/*----------------------
 Testimonials Section
----------------------*/

.testimonials {
    background-color: var(--black-300);
}

.testimonial-item {
    margin: 15px;
    padding: 30px;
    box-shadow: var(--shadow-black-100);
    border-radius: 10px;
    text-align: center;
    color: var(--bw);
}

.testimonial-item .img-box {
    height: 100px;
    width: 100px;
    display: inline-block;
    margin-bottom: 30px;
    border-radius: 50%;
    position: relative;
}

.testimonial-item .img-box img {
    border-radius: 50%;
    width: 100%;
}

.testimonial-item .img-box i {
    position: absolute;
    height: 30px;
    width: 30px;
    background-color: var(--gray2);
    border-radius: 50%;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    left: calc(100% - 15px);
    top: calc(50% - 15px);
}

.testimonial-item p {
    font-size: 16px;
    margin: 0 0 20px;
    color: var(--black-400);
    line-height: 26px;
    font-weight: 300;
}

.testimonial-item h3 {
    font-size: 18px;
    color: var(--black-900);
    font-weight: 500;
    margin: 0 0 5px;
    text-transform: capitalize;
}

.testimonial-item span {
    display: block;
    font-size: 16px;
    color: var(--black-400);
    font-weight: 300;
}

.testimonial-item .rating {
    margin-top: 10px;
}

.testimonial-item .rating i {
    display: inline-block;
    font-size: 16px;
    color: var(--color-2);
}

/*----------------------
 Pricing Section
----------------------*/

.pricing-plan {
    box-shadow: var(--shadow-black-100);
    border-radius: 10px;
    color: var(--bw);
}

.pricing-plan .pricing-header {
    padding: 20px 30px;
    border-bottom: 1px solid var(--black-alpha-100);
}

.pricing-plan .pricing-header h3 {
    text-align: center;
    font-size: 26px;
    color: #ECB365;
    font-weight: 500;
    text-transform: capitalize;
    margin: 0;
}

.pricing-plan .pricing-price {
    padding: 40px 30px;
    display: flex;
    justify-content: center;
    line-height: 0.7;
}

.pricing-plan .pricing-price .currency {
    font-size: 22px;
    font-weight: 300;
    color: var(--black-400);
    margin-right: 5px;
    align-self: flex-start;
}

.pricing-plan .pricing-price .price {
    font-size: 42px;
    font-weight: 700;
    color: var(--main-color);
}

.pricing-plan .pricing-price .period {
    font-size: 14px;
    font-weight: 300;
    color: var(--black-400);
    margin-right: 5px;
    align-self: flex-end;
    text-transform: uppercase;
}

.pricing-plan .pricing-body {
    padding: 0 30px;
}

.pricing-plan .pricing-body ul li {
    font-size: 16px;
    font-weight: 300;
    color: var(--black-400);
    padding: 8px 0 4px 30px;
    border-bottom: 1px solid var(--black-alpha-100);
    line-height: 26px;
    position: relative;
}

.pricing-plan .pricing-body ul li:last-child {
    border-bottom: none;
}

.pricing-plan .pricing-body ul li i {
    color: var(--main-color);
    position: absolute;
    left: 0;
    top: 12px;
}

.pricing-plan .pricing-footer {
    padding: 20px 30px 30px;
    text-align: center;
}


/*----------------------
 Contact Section
----------------------*/

.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--bw);
    padding-bottom: 340px;
    margin-top: 160px;
}

.contact {
    font-family: 'JetBrains Mono', monospace;
    color: var(--bw);
}

form {
    padding-top: 40px;
}

.contact-form {
    position: relative;
}

.contact-form .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.contact-form .form-group input[type="text"],
.contact-form .form-group textarea {
    width: 500px;
    margin-right: 30px;
    box-sizing: border-box;
    font-family: 'JetBrains Mono', monospace;
    color: var(--bw) !important;
}

.contact-form .form-group input[type="text"],
.contact-form .form-group textarea {
    border: none;
    border-bottom: 2px solid var(--gray4);
    font-size: 18px;
    font-weight: 400;
    padding: 10px 0;
    background-color: transparent;
    color: var(--bw);

}

.contact-form .form-group input[type="text"]:focus,
.contact-form .form-group textarea:focus {
    outline: none;
}

.contact-form textarea.form-control {
    height: 140px;
    padding-top: 12px;
    resize: none;
}

textarea,
input[type="text"],
input[type="button"],
input[type="submit"] {
    -webkit-appearance: none;
    border-radius: 0;
}

.contact-form button.btn-2 {
    position: absolute;
    color: var(--bw);
    font-size: 18px;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    right: 0px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-family: 'JetBrains Mono', monospace;
    margin-top: 50px;
}

.contact-form button.btn-2:hover {
    color: #ECB365;
}


.form-control::placeholder {
    color: var(--bw);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--gray2) inset !important;
    color: var(--bw) !important;
    font-size: normal !important;
    -webkit-text-fill-color: var(--bw) !important;
}

/*----------------------
 Footer Section
----------------------*/

.credits {
    color: var(--bw);
    padding-bottom: 10px;
    text-align: center;
}

.container {
    max-width: 1170px;
    margin: auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

ul {
    list-style: none;
}

.footer {
    transition: all .3s ease-in-out;
    border-radius: 0 15px 0 0;
    margin-top: 120px;
    font-family: 'JetBrains Mono', monospace;
    background-color: var(--gray2);
    padding: 80px 0;
}

.footer-col {
    width: 25%;
    padding: 0 15px;
}

.footer-col h4 {
    font-size: 18px;
    color: var(--bw);
    text-transform: capitalize;
    margin-bottom: 35px;
    font-weight: 700;
    position: relative;
}


.footer-col h4::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    border-radius: 2px;
    background-color: #9BA4B5;
    height: 2px;
    box-sizing: border-box;
    width: 20%;
}

.footer-col ul li:not(:last-child) {
    margin-bottom: 10px;
}

.footer-col ul li a {
    margin-top: 10px;
    font-size: 16px;
    text-transform: capitalize;
    color: var(--bw) !important;
    text-decoration: none;
    font-weight: 300;
    color: #bbbbbb;
    display: block;
    transition: all 0.3s ease;
}

.footer-col ul li a:hover {
    color: var(--bw);
    padding-left: 8px;
}


.footer-col .social-links a {
    display: inline-block;
    height: 40px;
    width: 40px;
    background-color: var(--emka);
    margin: 0 10px 10px 0;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #ffffff;
    transition: all 0.5s ease;
}

.footer-col .social-links a:hover {
    color: #24262b;
    background-color: #ffffff;
}

.footer .copyright-text {
    color: var(--bw);
    font-size: 16px;
    font-weight: 300;
    left: 0;
    right: 0;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}


/*----------------------
 Others Section
----------------------*/

.home,
.about,
.sale,
.news,
.raffles {
    min-height: 100%;
}

.home {
    max-width: 100%;
    overflow-x: hidden;
}

.about {
    justify-content: center;
    position: relative;
}

.aboutus {
    color: var(--bw);
    margin-top: 80px;
    margin-left: calc(10% - 22px) !important;
    margin-right: calc(10% - 22px) !important;
    font-size: 18px;

}

.copyright {
    color: var(--bw);
    justify-content: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.copyright p {
    margin-top: 40%;
    font-size: small;
    margin-left: 30px;
    margin-right: 30px;
}


.error {
    font-family: 'ff-cocon-pro';
    font-size: 60px;
    color: var(--bw);
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin-left: auto;
    margin-right: auto;
}

.error .liczba {
    color: #9BA4B5;
}

.kod {
    font-size: 30px;
    display: block;
}




/* --------------------
 Responsiveness
---------------------*/
@media only screen and (max-width: 1700px) {
    .content {
        padding-left: 260px;
        padding-right: 260px;
    }

    .startname {
        font-size: 20px;
    }

    .hello {
        line-height: 27px;
    }

    .welcome {
        font-size: 20px;
    }

    .namesubname {
        font-size: 54px !important;
    }

    .quote {
        padding-top: 10px;
        width: 460px;
        font-size: 18px;
    }

    .quote span {
        font-size: 16px;
        margin-right: 8px;
    }

    .resume-cont {
        margin-top: -30px;
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 1380px) {
    .content {
        padding-left: 220px;
        padding-right: 220px;
    }

    .startname {
        font-size: 19px;
    }

    .hello {
        line-height: 26px;
    }

    .welcome {
        font-size: 18px;
    }

    .namesubname {
        font-size: 50px !important;
    }

    .quote {
        padding-top: 10px;
        width: 460px;
        font-size: 18px;
    }

    .quote span {
        font-size: 16px;
        margin-right: 8px;
    }

    .resume-cont {
        margin-top: -30px;
        margin-bottom: 40px;
    }

    .contact-form button.btn-2 {
        left: 0px;
        right: auto;
    }



    .nav__links .barname {
        font-size: 15px;
    }

    .nav__links li {
        padding: 8px 12px;
        padding: 8px 10px;
    }

    .nav__links i {
        font-size: 15px;
        font-weight: 300;
    }

    .toggle-theme i {
        color: var(--bw);
        display: inline;
        padding-left: 10px;
    }

    .logo {
        font-size: 1.55rem;
    }

    .description {
        font-size: 14px;
    }

    .projects .description span {
        margin-top: 10px;
    }

}


@media only screen and (max-width: 1200px) {
    header {
        padding-top: 14px;
        padding-bottom: 14px;
    }

    .nav__links {}

    .nav__links .barname {
        font-size: 15px;
    }

    .nav__links li {
        padding: 8px 12px;
        padding: 8px 8px;
    }

    .nav__links i {
        font-size: 15px;
        font-weight: 300;
    }

    .toggle-theme i {
        color: var(--bw);
        display: inline;
        padding-left: 10px;
    }

    .logo {
        font-size: 1.55rem;
    }

    .logo {
        font-size: 1.4rem;
    }


    .footer {
        padding: 160px 0 !important;
    }

}

@media only screen and (max-width: 1200px) {
    .content {
        padding-left: 80px;
        padding-right: 80px;
    }

    .social-icons {
        flex-direction: row;
        position: relative;
        left: auto;
        bottom: auto;
        justify-content: left;
    }

    .social-icons .line {
        display: none;
    }

    .social-icons i {
        padding-right: 26px;
    }

    .r-icons {
        display: none;
    }

    .startname {
        font-size: 19px;
    }

    .hello {
        line-height: 26px;
    }

    .welcome {
        font-size: 18px;
    }

    .namesubname {
        font-size: 50px !important;
    }

    .quote {
        padding-top: 10px;
        width: 460px;
        font-size: 18px;
    }

    .quote span {
        font-size: 16px;
        margin-right: 8px;
    }

    .resume-cont {
        margin-top: -30px;
        margin-bottom: 40px;
    }

    .contact-form .form-group {
        padding-right: 64px;
    }

}

@media only screen and (max-width: 992px) {
    .content {
        padding-left: 60px;
        padding-right: 60px;
    }

    .startname {
        font-size: 18px;
    }

    .hello {
        line-height: 24.8px;
    }

    .welcome {
        font-size: 17px;
    }

    .namesubname {
        font-size: 46px !important;
    }

    .quote {
        padding-top: 10px;
        width: 400px;
        font-size: 16px;
    }

    .quote span {
        font-size: 15px;
        margin-right: 0px;
    }

    .resume-cont {
        margin-top: -30px;
        margin-bottom: 40px;
    }

    .projects-more {
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 20px;
    }

    .projects-more .description {
        height: 210px;
        font-size: 16px;
    }

    .project {
        height: 270px;
    }

    .description span {
        margin-top: 10px;
    }

    .projects {
        padding-bottom: 80px;
    }

    .contact {
        margin-top: 80px;
    }
}

@media only screen and (max-width: 768px) {
    .content {
        padding-left: 30px;
        padding-right: 30px;
    }

    .startname {
        font-size: 17px;
    }

    .hello {
        line-height: 24px;
    }

    .welcome {
        font-size: 16px;
    }

    .namesubname {
        font-size: 44px !important;
    }

    .quote {
        padding-top: 10px;
        width: 360px;
        font-size: 16px;
    }

    .quote span {
        font-size: 14px;
        margin-right: 12px;
    }

    .resume-cont {
        margin-top: -30px;
        margin-bottom: 40px;
    }

    .titleabout {
        font-size: 20px;
    }

    .textabout {
        font-size: 14px;
    }

    .langicons {
        font-size: 14px;
    }

    .credits {
        font-size: smaller;
    }

    .quote {
        display: none;
    }

    .project {
        height: auto;
    }

    .project .description {
        font-size: 18px;
    }

    .projects {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

}

@media only screen and (max-width: 600px) {
    .content {
        padding-left: 22px;
        padding-right: 22px;
    }

    .startname {
        font-size: 15px;
    }

    .hello {
        line-height: 23px;
    }

    .welcome {
        font-size: 14px;
    }

    .namesubname {
        font-size: 40px !important;
    }

    .quote {
        padding-top: 10px;
        width: 80%;
        font-size: 14px;
    }

    .quote span {
        font-size: 12px;
        margin-right: 0;
    }

    .resume-cont {
        margin-top: -30px;
        margin-bottom: 40px;
    }



    .titleabout {
        font-size: 20px;
    }

    .textabout {
        font-size: smaller;
    }

    .langicons {
        font-size: smaller;
    }

    .owl-item {
        font-size: 14px;
    }

    .contact-form .form-group input[type="text"],
    .contact-form .form-group textarea {
        max-width: 340px;
        font-size: 15px;
    }

    .credits {
        font-size: small;
    }


    header {
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: var(--gray2) !important;
        backdrop-filter: blur(0px) !important;
    }


    .nav__links .barname {
        font-size: 12px;
    }


    .nav__links li {
        padding: 8px 12px;
        padding: 8px 5px;
    }

    .nav__links i {
        color: var(--bw);
        font-size: 12px;
        font-weight: 300;
        display: none;
    }

    .toggle-theme i {
        color: #ECB365;
        display: inline;
        padding-left: 4px;
    }


    .menu {
        display: initial;
    }

    .navigation {
        display: flex;
    }

    .logo {
        font-size: 1.4rem;
    }

    .projects-more .description {
        height: 210px;
        font-size: 14px;
        background-color: var(--gray2);
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    }


    .project .description {
        font-size: smaller;
        line-height: smaller;
    }

    .project span {
        margin-top: 4px !important;
    }

    .projects {
        padding-bottom: 60px;
    }

    .contact {
        margin-top: 60px;
    }

    .contact-form button.btn-2 {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 400px) {

    .contact-form .form-group input[type="text"],
    .contact-form .form-group textarea {
        max-width: 300px;
    }

    .project {
        height: 250px;
    }
}

.animation {
    opacity: 0;
}


.animation-show {
    opacity: 1;
    animation: sliding 1.2s;
}

/*
.animation-show~.hello {
    opacity: 0.85;
    animation: sliding2 1.2s;
}*/

.animation-show~.social-icons {
    animation: sliding3 2s;
}


@keyframes sliding {
    0% {
        transform: translateY(30px);
        opacity: 0;
    }

    30% {
        transform: translateY(30px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }

}

@keyframes sliding2 {
    0% {
        transform: translateY(30px);
        opacity: 0;
    }

    30% {
        transform: translateY(30px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 0.85;
    }
}

@keyframes sliding3 {
    0% {
        transform: translateY(30px);
        opacity: 0;
    }

    80% {
        transform: translateY(30px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}